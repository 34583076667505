<template>
  <div>
    <div class="sellers-conclusion-container">
      <h1 class="sellers-conclusion-title">Amplia tu negocio sin límite</h1>
      <p class="sellers-conclusion-subtitle">Todas las herramientas que necesitas para impulsar tu negocio a la venta online, sin inversión, sin permanencia, sin preocupaciones.</p>
      <div class="product-conclusion-container" @mouseover='animation()' @mouseout='animationOut()'>
        <div class="product-conclusion-row">
        </div>
        <div class="">
          <div class="grid-container" >
            <p class="more-text" id="text-animation">Solicitar información</p>
            <svg width="27" height="14" viewBox="0 0 27 14" fill="none" xmlns="http://www.w3.org/2000/svg"  alt="arrow" id="arrow-animation" >
              <path d="M0 7H25" stroke="#307857" stroke-width="2"/>
              <path d="M19 1L25 7L19 13" stroke="#307857" stroke-width="2"/>
            </svg>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
export default { 
  methods: {
      animation: function () {
        document.getElementById("arrow-animation").style.transition = "transform 0.3s";
        document.getElementById("arrow-animation").style.transform = "translateX(8px)";
        document.getElementsByTagName("body")[0].style.cursor = "pointer";
      },
      animationOut: function () {
        document.getElementById("arrow-animation").style.transform = "translateX(0px)";
        document.getElementsByTagName("body")[0].style.cursor = "auto";
      }
    }
  }
</script>

<style scoped>

.sellers-conclusion-container{
  background-color: rgb(245, 247, 250);
  padding-bottom: 5%;
}

.product-conclusion-row{
  background-image: url('../assets/conclusion2.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 300px;
}


.sellers-conclusion-title{
  padding-top: 10%;
  padding-right: 4%;
  padding-left: 4%;
  padding-bottom: 5%;
  text-align: center;
  font-weight: 900;
  font-size: 200%;
  color: #000;
}

@media only screen and (min-width: 768px) {
  .sellers-conclusion-title{
    font-size: 250%;
    padding-top: 5%;
    padding-bottom: 0%;
  }
}

.sellers-conclusion-subtitle{
  padding-top: 0.5%;
  padding-left: 20%;
  padding-right: 20%;
  font-size: 110%;
  padding-bottom: 2%;
}

.product-conclusion-container{
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 5px 10px -3px rgba(0,0,0,0.1);
}

.grid-container{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.grid-container p{
  padding: 0;
  margin: 0 10px;
}

@media only screen and (min-width: 768px) {
  .product-conclusion-container{
    padding-top: 1%;
    padding-bottom: 1%;
    margin-right: 30%;
    margin-left: 30%;
  }
}

.product-conclusion-row{
  padding-top: 1%;
}

.more-text{
  text-align: center;
  padding-top: 1%;
  font-size: 110%;
  color: #307857;
  font-weight: 700;
  padding-bottom: 3%;
  padding-left: 0%;
  padding-right: 0%;
}

</style>