<template>
  <div class="info-container">
      <h1 class="sellers-title">Entra, añade, vende.</h1>
      <p class="sellers-subtitle">Con nuestro dashboard tienes todo el control de tus productos y pedidos.</p>
      <div class="">
        <div class="grid-info-container" @mouseover='infoAnimation()' @mouseout='infoAnimationOut()'>
            <p class="more-text" id="info-text-animation">Empezar a utilizar el dashboard</p>
            <svg width="27" height="14" viewBox="0 0 27 14" fill="none" xmlns="http://www.w3.org/2000/svg" id="info-arrow-animation" >
                <path d="M0 7H25" stroke="#307857" stroke-width="2"/>
                <path d="M19 1L25 7L19 13" stroke="#307857" stroke-width="2"/>
            </svg>
        </div>
      </div>
      <div class="sellers-info-table">
        <div class="row">
            <div class="col-12 col-md-6">
                <h1 class="info-title">Tu control, tu espacio.</h1>
                <p class="info-subtitle">El dashboard de Avasto será tu aliado indispensable. Accede a él desde cualquier dispositivo (Movil, Tablet y Ordenador) y despliega todo  el potencial de tu negocio sin coste alguno.</p>
            </div>
            <div class="col-12 col-md-6 seller-image image1-container"></div>
        </div>
        <div class="row seller-dashboard-padding">
            <div class="col-12 col-md-6 d-none d-md-block image2-container"></div>
            <div class="col-12 col-md-6">
                <h1 class="info-title">Gestiona tus productos de manera rápida y sencilla</h1>
                <p class="info-subtitle">El dashboard de Avasto será tu aliado indispensable. Accede a él desde cualquier dispositivo (Movil, Tablet y Ordenador) y despliega todo  el potencial de tu negocio sin coste alguno.</p>
            </div>
            <div class="col-12 col-md-6 d-md-none image2-container"></div>
        </div>
        <div class="row seller-dashboard-padding">
            <div class="col-12 col-md-6">
                <h1 class="info-title">Controla en todo momento los pedidos</h1>
                <p class="info-subtitle">El dashboard de Avasto será tu aliado indispensable. Accede a él desde cualquier dispositivo (Movil, Tablet y Ordenador) y despliega todo  el potencial de tu negocio sin coste alguno.</p>
            </div>
            <div class="col-12 col-md-6 image3-container"></div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    methods: {
      infoAnimation: function () {
        document.getElementById("info-arrow-animation").style.transition = "transform 0.5s";
        document.getElementById("info-arrow-animation").style.transform = "translateX(10px)";
        document.getElementById("info-text-animation").style.textDecoration = "underline";
        document.getElementsByTagName("body")[0].style.cursor = "pointer";
      },
      infoAnimationOut: function () {
        document.getElementById("info-arrow-animation").style.transform = "translateX(0px)";
        document.getElementById("info-text-animation").style.textDecoration = "none";
        document.getElementsByTagName("body")[0].style.cursor = "auto";
      }
    }
}
</script>

<style scoped>

.sellers-title{
  padding-top: 12%;
  padding-bottom: 4%;
  text-align: center;
  font-weight: 900;
  font-size: 200%;
  color: #000;
}

@media only screen and (min-width: 768px) {
    .sellers-title{
        padding-top: 5%;
        font-size: 250%;
        padding-bottom: 0%;
    }
}

.sellers-subtitle{
    padding-top: 0.5%;
    padding-left: 20%;
    padding-right: 20%;
    font-size: 110%;
}

.grid-info-container{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.grid-info-container p{
  padding: 0;
  margin: 0 10px 0 0;
}

.more-text{
    text-align: center;
  padding-top: 1%;
  font-size: 110%;
  color: #307857;
  font-weight: 700;
  padding-bottom: 3%;
  padding-left: 0%;
  padding-right: 0%;
}

.info-container{
    padding-bottom: 10%;
}

.img-responsive {
    margin: 0 auto;
}

.sellers-info-table{
    padding-top: 6%;
    padding-left: 5%;
    padding-right: 5%;
}

@media only screen and (min-width: 768px) {

    .sellers-info-table{
        padding-left: 15%;
        padding-right: 15%;
    }
}

.info-title{
    padding-top: 10%;
    font-weight: 900;
    font-size: 190%;
    color: #000;
    text-align: center;
}

@media only screen and (min-width: 768px) {
    .info-title{
        text-align: start;
    }
}

.info-subtitle{
    padding-top: 3%;
    text-align: center;
    font-size: 110%;
}

@media only screen and (min-width: 768px) {
    .info-subtitle{
        text-align: start;
    }
}

.image1-container {
    background-image: url('../assets/bg3.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 300px;
}

.image2-container {
    background-image: url('../assets/bg3.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 300px;
}

.image3-container {
    background-image: url('../assets/bg3.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 300px;
}

.seller-dashboard-padding{
    padding-top: 15%;
}

@media only screen and (min-width: 768px) {
    .seller-dashboard-padding{
        padding-top: 10%;
    }
}

</style>