<template>
  <div class="help-dashboard-container">
    <div class="grid-title-container">
      <img id="process1-icon" src="../assets/dashboardApp.png" height="40" alt="serach"/>
      <h1 class="help-item-title">Dashboard Avasto</h1>
      <!--<hr class="separator"> -->
    </div>
    <div class="faq">
      <div class="accordion" id="accordionPanelsStayOpenExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="heading3">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
              ¿Como puedo obtener una cuenta de vendedor?
            </button>
          </h2>
          <div id="collapse3" class="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
              No puedo acceder con las claves al dashboard
            </button>
          </h2>
          <div id="collapseOne" class="accordion-collapse collapse block" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              Si al intentar acceder al dashboard con tus claves te sale un error indicando que no son correctas, primero de todo comprueba que has introducido correctamente las claves, si no funciona contacta directamentecon el equipo de soporte para poder darte unas nuevas claves lo antes posible.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Quiero cambiar la contraseña
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="heading4">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
              ¿Qué puedo hacer en el dashboard?
            </button>
          </h2>
          <div id="collapse4" class="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="heading5">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
              ¿Donde puedo añadir/editar mis productos?
            </button>
          </h2>
          <div id="collapse5" class="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

.help-dashboard-container{
    text-align: start;
    padding-top: 2%;
    padding-right: 15%;
    padding-left: 15%;
    padding-bottom: 20%;
    background-color: rgb(252, 252, 252);
}

.grid-title-container{
  display: flex;
  align-items: center;
  padding-top: 3%;
  padding-bottom: 2%;
}

.grid-title-container h1{
  font-weight: 700;
  font-size: 150%;
  margin: 0%;
  padding-left: 10px;
}

.separator{
  margin-left: 20px;
  height:2px;
  width: 50%;
  border-width:0;
  color:gray;
  background-color:gray
}

.faq{
  padding-top: 1%;
}

</style>