<template>
  <div class="home-info-container">
    <p class="info-pre-title">¿OTRO MARKETPLACE IGUAL?</p>
    <h1 class="info-title">Un mercado más social que nunca</h1>
    <p class="info-subtitle">Con Avasto queremos que comprar no sea simplemente añadir un producto y disfrutarlo. Podrás tener más información acerca del producto y más, gracias a las proximas funcionalidades que se incorporarán a Avasto en muy poco tiempo.</p>
    <div class="product-info-image"></div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.home-info-container{
    padding-bottom: 10%;
    background-color: rgb(252, 252, 252);
}

.info-pre-title{
    color: rgb(108, 111, 126);
    font-weight: 600;
    padding-top: 15%;
}

@media only screen and (min-width: 768px) {
    .info-pre-title{
        padding-top: 5%;
    }
}

.info-title{
    font-family: 'Nunito Sans', sans-serif;
    padding-right: 6%;
    padding-left:6%;
    text-align: center;
    font-weight: 900;
    font-size: 200%;
    padding-top: 1%;
    color: #000;
}


@media only screen and (min-width: 768px) {
  .info-title{
    font-size: 250%;
    padding-top: 0%;
    padding-bottom: 0%;
    padding-right: 4%;
    padding-left: 4%;
  }
}

.info-subtitle{
    padding-right: 8%;
    padding-left: 8%;
    padding-top: 1%;
}

@media only screen and (min-width: 768px) {
    .info-subtitle{
        padding-right: 10%;
        padding-left: 10%;
    }
}

@media only screen and (min-width: 1013px) {
    .info-subtitle{
        padding-right: 25%;
        padding-left: 25%;
    }
}

.product-info-image{
    margin-top:12%;
    margin-bottom: 13%;
    background-image: url('../assets/perfil3.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 80px;
}

@media only screen and (min-width: 768px) {
    .product-info-image{
        height: 110px;
    }
}

@media only screen and (min-width: 768px) {
    .product-info-image{
        margin-top: 3%;
        margin-bottom: 0%;
    }
}

</style>