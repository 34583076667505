<template>
  <div class="top">
      <div class="title-container">
        <div class="app-logo-row">
          <div class="app-logo-container"></div>
        </div>
        <h1 class="title">Revolucionando el comercio local</h1>
        <p class="sub-title">Avasto pone en contacto productores locales cercanos contigo, disfruta de sus productos de primera calidad mientras ayudas a un sector cada vez más olvidado y fuertemente azotados por los grandes monopolios.</p>
        <div class="title-button-container">
          <router-link
              class=""
              to="/"
              active-class="active">
              <button type="button" class="title-button btn">Descargar la aplicación</button>
          </router-link>
        </div>
      </div>
      <div class="ventajas-app">
        <div class="row">
          <div class="col-12 col-md-4"  @mouseover='homeAnimation1()' @mouseout='homeAnimationOut1()' >
            <p class="home-head-ventajas-title">Productos de primera calidad fabricados por productores durante años para perfeccionar sus productos</p>
            <div class="home-head-container-grid">
              <p class="home-head-more-text" id="home-text-animation-1">Más información</p>
              <svg id="home-icon-animation-1" width="27" height="14" viewBox="0 0 27 14" fill="none" xmlns="http://www.w3.org/2000/svg"  alt="arrow">
                <path d="M0 7H25" stroke="#307857" stroke-width="2"/>
                <path d="M19 1L25 7L19 13" stroke="#307857" stroke-width="2"/>
              </svg>
            </div>
          </div>
          <div class="col-12 col-md-4 ventajas-middle-element" @mouseover='homeAnimation2()' @mouseout='homeAnimationOut2()'>
            <p class="home-head-ventajas-title">Productos de primera calidad fabricados por productores durante años para perfeccionar sus productos</p>
            <div class="home-head-container-grid">
              <p class="home-head-more-text" id="home-text-animation-2">Más información</p>
              <svg id="home-icon-animation-2" width="27" height="14" viewBox="0 0 27 14" fill="none" xmlns="http://www.w3.org/2000/svg"  alt="arrow">
                <path d="M0 7H25" stroke="#307857" stroke-width="2"/>
                <path d="M19 1L25 7L19 13" stroke="#307857" stroke-width="2"/>
              </svg>
            </div>
          </div>
          <div class="col-12 col-md-4" @mouseover='homeAnimation3()' @mouseout='homeAnimationOut3()'>
            <p class="home-head-ventajas-title">Productos de primera calidad fabricados por productores durante años para perfeccionar sus productos</p>
            <div class="home-head-container-grid">
              <p class="home-head-more-text" id="home-text-animation-3">Más información</p>
              <svg id="home-icon-animation-3" width="27" height="14" viewBox="0 0 27 14" fill="none" xmlns="http://www.w3.org/2000/svg"  alt="arrow">
                <path d="M0 7H25" stroke="#307857" stroke-width="2"/>
                <path d="M19 1L25 7L19 13" stroke="#307857" stroke-width="2"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  methods: {
    homeAnimation1: function () {
      document.getElementById("home-icon-animation-1").style.transition = "transform 0.3s";
      document.getElementById("home-icon-animation-1").style.transform = "translateX(8px)"
      document.getElementsByTagName("body")[0].style.cursor = "pointer";
    },
    homeAnimationOut1: function () {
      document.getElementById("home-icon-animation-1").style.transform = "translateX(0px)";
      document.getElementsByTagName("body")[0].style.cursor = "auto";
    },
    homeAnimation2: function () {
      document.getElementById("home-icon-animation-2").style.transition = "transform 0.3s";
      document.getElementById("home-icon-animation-2").style.transform = "translateX(8px)";
      document.getElementsByTagName("body")[0].style.cursor = "pointer";
    },
    homeAnimationOut2: function () {
      document.getElementById("home-icon-animation-2").style.transform = "translateX(0px)";
      document.getElementsByTagName("body")[0].style.cursor = "auto";
    },
    homeAnimation3: function () {
      document.getElementById("home-icon-animation-3").style.transition = "transform 0.3s";
      document.getElementById("home-icon-animation-3").style.transform = "translateX(8px)";
      document.getElementsByTagName("body")[0].style.cursor = "pointer";
    },
    homeAnimationOut3: function () {
      document.getElementById("home-icon-animation-3").style.transform = "translateX(0px)";
      document.getElementsByTagName("body")[0].style.cursor = "auto";
    }
  }
}
</script>

<style scoped>
.top{
  background-color: rgb(250, 250, 250);
  padding-bottom: 5%;
} 

@media only screen and (min-width: 1013px) {
  .top{
    background-image: url("../assets/bg2-1.png");
    background-size: cover;
  } 
}

.title-container{
  text-align: center;
  padding-top: 15%;
  padding-bottom: 17%;
}

@media only screen and (min-width: 768px) {
  .title-container{
    padding-top: 3%;
    padding-bottom: 0%;
  }
} 

.app-logo-row{
  width: 100vw;
  display: flex;
  justify-content: center;
  margin-bottom: 1.0%;
}

.app-logo-container{
  text-align: center;
  background-image: url("../assets/logo6-9-1.png");
  background-size: cover;
  width: 120px;
  height: 120px;
  border-radius: 18px;
}

.title{
  font-size: 240%;
  line-height: 45px;
  font-weight: 900;
  color: #000;
  padding-left: 8%;
  padding-right: 8%;
  padding-top: 6%;
  padding-bottom: 3%;
  font-family: 'Nunito Sans', sans-serif;
}

@media only screen and (min-width: 768px) {
  .title{
    font-size: 500%;
    line-height: 80px;
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 1%;
    padding-bottom: 0%;
  }
} 

@media only screen and (min-width: 1013px) {
  .title{
    font-size: 500%;
    line-height: 80px;
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 1%;
    padding-bottom: 0%;
  }
}

.sub-title{
  font-family: 'Nunito Sans', sans-serif;
  padding-top: 3%;
  font-size: 115%;
  padding-left: 4%;
  padding-right: 4%;
}

@media only screen and (min-width: 768px) {
  .sub-title{
    padding-top: 1%;
    padding-left: 20%;
    padding-right: 20%;
    font-size: 115%;
  }
} 

.title-button-container{
  padding-top: 10%;
  display: inline-block;
}

@media only screen and (min-width: 768px) {
  .title-button-container{
    padding-top: 3%;
    display: inline-block;
  }
}

.title-button{
  font-family: 'Nunito Sans', sans-serif;
  height: 60px;
  width: 210px;
  border-radius: 5px;
  color: #FFF;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: rgb(48, 120, 87);
  vertical-align: middle;
} 

.title-button:hover{
  color: #000;
}

.ventajas-app{
  margin-top: 0%;
  margin-left: 8%;
  margin-right: 8%;
}

@media only screen and (min-width: 768px) {
  .ventajas-app{
    margin-top: 5%;
    margin-left: 15%;
    margin-right: 15%;
  }
}

.ventajas-app-sub-container{
  padding: 7%;
}

.ventajas-sub-container{
  text-align: start;
}

.ventajas-title{
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 900;
  font-size: 150%;
  text-align: start;
}

.ventajas-sub-title{
  font-family: 'Nunito Sans', sans-serif;
  color: #848484;
  font-weight: 600;
}

.ventajas-middle-element{
  margin-top: 4%;
  margin-bottom: 4%;
  padding-top: 4%;
  padding-bottom: 4%;
  border-top: 1px solid rgb(192, 192, 192);
  border-bottom: 1px solid rgb(192, 192, 192);
}

@media only screen and (min-width: 768px) {
  .ventajas-middle-element{
    margin-top: 0%;
    margin-bottom: 0%;
    padding-top: 0%;
    padding-bottom: 0%;
    border-top: none;
    border-bottom: none;
    border-left: 1px solid rgb(192, 192, 192);
    border-right: 1px solid rgb(192, 192, 192);
  }
}

.home-head-ventajas-title{
  text-align: start;
  margin-bottom: 10px;
}

@media only screen and (min-width: 768px) {
  .home-head-ventajas-title{
    margin-bottom: 16px;
  }
}

.home-head-container-grid{
  display: flex;
  align-items: center;
  justify-content: start;
}

.home-head-container-grid p{
  font-family: 'Nunito Sans', sans-serif;
  padding: 0;
  margin: 0;
  margin-right: 10px;
}


.home-head-more-text{
  font-family: 'Nunito Sans', sans-serif;
  text-align: center;
  padding-top: 1%;
  font-size: 100%;
  color: #307857;
  font-weight: 700;
  padding-bottom: 3%;
  padding-left: 0%;
  padding-right: 0%;
}
</style>