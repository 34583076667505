<template>
  <div>
    <NewSellerHead/>
    <NewSellerForm/>
    <PageFooter2/>
  </div>
</template>

<script>
import NewSellerHead from '@/components/NewSellerHeadComponent.vue'
import NewSellerForm from '@/components/NewSellerFormComponent.vue'
import PageFooter2 from '@/components/PageFooter2.vue'

export default {
    components: {
        NewSellerHead,
        NewSellerForm,
        PageFooter2
    }
}
</script>

<style>

</style>