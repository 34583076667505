<template>
    <div class="seller-form-container text-center">
        <form ref="form" class="form-inline justify-content-center align-items-center" @submit.prevent="openModal">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <input name="name" class="textfield form-control email-input" placeholder="Nombre">
                </div>
                <div class="col-12 col-lg-6">
                    <input name="surname" class="form-control email-input" placeholder="Apellidos">
                </div>
            </div>
            <input type="email" name="email" class="form-control email-input" placeholder="Correo Electrónico">
            <textarea class="form-control email-input" name="message" id="exampleFormControlTextarea1" placeholder="Explica brevemente a que se dedica tu empresa y que tipo de productos vende" rows="3"></textarea>
            <button type="submit" class="send-button btn" data-bs-toggle="modal" data-bs-target="#myModal">Enviar</button>
        </form>
    </div>
    <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="fixedContainer d-flex justify-content-end" data-bs-dismiss="modal">
                <i class="fa-solid fa-xmark fa-2x"></i>
                </div>
                <div class="modal-body">
                    <img src="../assets/ok.png" alt="Check correcto" height="60"/>
                    <h2 class="modal-info-title">Información enviada</h2>
                    <p class="modal-info-text">Nos pondremos en contacto contigo muy pronto.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script defer>
import emailjs from 'emailjs-com';
export default {
    methods: {
        sendEmail() {            
            try {
                emailjs.sendForm('service_wqz2vlq', 'template_v4bx2j7', this.$refs.form,
                'HVPcLV7-xmFctanBN').then(()=> {
                        document.querySelector(".modal").classList.add("show");
                        document.querySelector(".modal").style.display = "block";
                    }, (error) => {
                        console.log(error.text);
                    }
                )
            } catch(error) {
                console.log({error})
            }
            // Reset form field
            
        },
    }

}
</script>

<style>

.fixedContainer {
  width: auto;
  height: 50px;
  top: 30px;
  padding-right: 10px;
  align-items: flex-end;
  justify-content: end;
  
}

.seller-form-container{
    padding-top: 2%;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 15%;
}

@media only screen and (min-width: 1013px) {
    .seller-form-container{
        padding-left: 33%;
        padding-right: 33%;
        padding-bottom: 5%;
    }
}

.email-input{
    margin-top: 4%;
}

.send-button{
  height: 40px;
  width: 130px;
  border-radius: 5px;
  color: #FFF;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: #003506;
  vertical-align: middle;
  margin-top: 10%;
}

@media only screen and (min-width: 1013px) {
    .send-button{
        margin-top: 5%;
    }
}

.modal-info-title{
    padding-top: 4%;
}

.modal-info-text{
    padding-bottom: 7%;
}


</style>