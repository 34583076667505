<template>
    <div class="home-process-container">
        <p class="process-pre-title">PÍDELEO. RECÍBELO. DISFRÚTALO.</p>
        <h1 class="process-title">Aplicación a prueba de envíos</h1>
        <p class="process-subtitle">Productos antes nunca vistos y de gran calidad llegan a la puerta de tu casa de la mano de Avasto. Haz tu pedido y descubre un nuevo horizontes de productos.</p>
        <div class="process-container-grid">
            <p class="process-more-text" id="product-text-animation"  @mouseover='showProductAnimation()' @mouseout='showProductAnimationOut()'>Acerca del envío</p>
            <svg  id="product-arrow-animation" width="27" height="14" viewBox="0 0 27 14" fill="none" xmlns="http://www.w3.org/2000/svg"  alt="arrow">
                <path d="M0 7H25" stroke="#307857" stroke-width="2"/>
                <path d="M19 1L25 7L19 13" stroke="#307857" stroke-width="2"/>
            </svg>
        </div>
        <div class="process-info-container row">
            <div class="col-12 col-md-6 col-lg-3 p-3">
                <div id="process1" @mouseover='animationProcess1()' @mouseout='animationOutProcess1()' class="process-item-row col-12">
                    <div class="process-item-container">
                        <i class="search-icon fa-solid fa-magnifying-glass"></i>
                        <h1 class="process-item-title">Buscar productos</h1>
                        <p class="process-item-subtitle">Añade al carrito tus productos favoritos, desde la aplicación podrás encontrar una variedad de productos y opciones para que encuentres la que más se aproxima a tus gustos.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3 p-3">
                <div id="process2" @mouseover='animationProcess2()' @mouseout='animationOutProcess2()' class="process-item-row col-12">
                    <div class="process-item-containe">
                        <i class="cart-icon fa-solid fa-cart-plus"></i>
                        <h1 class="process-item-title">Procesar el pedido</h1>
                        <p class="process-item-subtitle">Añade toda la información necesario para realizar el pedido como tu dirección a la que enviar los productos o una tarjeta bancaria para realizar el pago al productor.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3 p-3">
                <div id="process3" @mouseover='animationProcess3()' @mouseout='animationOutProcess3()' class="process-item-row col-12">
                    <div class="process-item-container">
                        <i class="truck-icon fa-solid fa-truck"></i>
                        <h1 class="process-item-title">Envío de tu pedido</h1>
                        <p class="process-item-subtitle">Envio rápido y efectivo. Recibe los productos en la puerta de tu casa. Controla el envio desde la aplicación de Avasto</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3 p-3">
                <div id="process4" @mouseover='animationProcess4()' @mouseout='animationOutProcess4()' class="process-item-row col-12">
                    <div class="process-item-container">
                        <i class="package-icon fa-solid fa-box-open"></i>
                        <h1 class="process-item-title">Disfruta de tus productos</h1>
                        <p class="process-item-subtitle">Añade al carrito tus productos favoritos, desde la aplicación podrás encontrar una variedad de productos y op.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        showProductAnimation: function () {
            document.getElementById("product-arrow-animation").style.transition = "transform 0.3s";
            document.getElementById("product-arrow-animation").style.transform = "translateX(8px)";
            document.getElementById("product-text-animation").style.textDecoration = "underline";
            document.getElementsByTagName("body")[0].style.cursor = "pointer";
        },
        showProductAnimationOut: function () {
            document.getElementById("product-arrow-animation").style.transform = "translateX(0px)";
            document.getElementById("product-text-animation").style.textDecoration = "none";
            document.getElementsByTagName("body")[0].style.cursor = "auto";
        },
        animationProcess1: function () {
            document.getElementById("process1").style.transition = "box-shadow 0.4s";
            document.getElementById("process1").style["boxShadow"] = "rgba(0, 0, 0, 0.10) 0px 8px 16px";
        },
        animationOutProcess1: function () {
            document.getElementById("process1").style["boxShadow"] = "none";
        },
        animationProcess2: function () {
            document.getElementById("process2").style.transition = "box-shadow 0.4s";
            document.getElementById("process2").style["boxShadow"] = "rgba(0, 0, 0, 0.10) 0px 8px 16px";
            document.getElementsByTagName("body")[0].style.cursor = "default";
        },
        animationOutProcess2: function () {
            document.getElementById("process2").style["boxShadow"] = "none";
        },
        animationProcess3: function () {
            document.getElementById("process3").style.transition = "box-shadow 0.4s";
            document.getElementById("process3").style["boxShadow"] = "rgba(0, 0, 0, 0.10) 0px 8px 16px";
            document.getElementsByTagName("body")[0].style.cursor = "default";
        },
        animationOutProcess3: function () {
            document.getElementById("process3").style["boxShadow"] = "none";
        },
        animationProcess4: function () {
            document.getElementById("process4").style.transition = "box-shadow 0.4s";
            document.getElementById("process4").style["boxShadow"] = "rgba(0, 0, 0, 0.10) 0px 8px 16px";
            document.getElementsByTagName("body")[0].style.cursor = "default";
        },
        animationOutProcess4: function () {
            document.getElementById("process4").style["boxShadow"] = "none";
        }
    }
}
</script>

<style scoped>

.home-process-container{
    padding-top: 8%;
    padding-bottom: 2%;
    background-color: rgb(252, 252, 252);
}

@media only screen and (min-width: 768px) {
    .home-process-container{
        padding-bottom: 2%;
        padding-top: 0%;
    }
}

.process-pre-title{
    color: rgb(108, 111, 126);
    font-weight: 600;
    padding-top: 5%;
}

@media only screen and (min-width: 768px) {
    .process-pre-title{
        color: rgb(108, 111, 126);
        font-weight: 600;
        padding-top: 4%;
    }
}

.process-title{
    font-family: 'Nunito Sans', sans-serif;
    padding-right: 6%;
    padding-left:6%;
    text-align: center;
    font-weight: 900;
    font-size: 200%;
    padding-top: 1%;
    color: #000;
}


@media only screen and (min-width: 768px) {
  .process-title{
    font-size: 250%;
    padding-top: 0%;
    padding-bottom: 0%;
    padding-right: 4%;
    padding-left: 4%;
  }
}

.process-subtitle{
    padding-right: 5%;
    padding-left: 5%;
    padding-top: 1%;
}

@media only screen and (min-width: 768px) {
    .process-subtitle{
        padding-right: 10%;
        padding-left: 10%;
    }
}

@media only screen and (min-width: 1013px) {
    .process-subtitle{
        padding-right: 25%;
        padding-left: 25%;
    }
}

.process-container-grid{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1%;
}

.process-container-grid p{
  padding: 0;
  margin: 0;
  margin-right: 10px;
}

.process-more-text{
    font-family: 'Nunito Sans', sans-serif;
    text-align: center;
    padding-top: 1%;
    font-size: 105%;
    color: #307857;
    font-weight: 700;
    padding-bottom: 3%;
    padding-left: 0%;
    padding-right: 0%;
}

.process-info-container{
    --bs-gutter-x: 0rem;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 8%;
}

@media only screen and (min-width: 1200px) {
    .process-info-container{
        padding-left: 15%;
        padding-right: 15%;
        padding-top: 3%;
    }
}

.process-item-row{
    padding: 10%;
    background-color: white;
    border-radius: 15px;
    border: 1px solid rgb(213, 213, 213);
    text-align: start;
    height: 100%;
}

.process-item-title{
    font-family: "Glacial Indifference", sans-serif;
    padding-top: 6%;
    padding-bottom: 3%;
    font-weight: 800;
    color: #000;
    font-size: 180%;
}

.search-icon{
    color: #92BBB0;
    font-size:27px;
}

.cart-icon{
    color: rgb(0, 77, 37, 0.65);
    font-size:27px;
}

.truck-icon{
    color: #3D896A;
    font-size:27px;
}

.package-icon{
    color: #004D2C;
    font-size:27px;
}
</style>