<template>
  <div class="home-container">
    <div class="row home-title-container">
        <div class="col-12 col-lg-6">
            <h1 class="home-title">Solución de digitalización simplificada </h1>
            <p class="home-subtitle">Ayudamos a los productores a abrir un nuevo canal de venta online sin coste alguno, da a conocer tus produtos a una clientela nueva.</p>
            <router-link
              class=""
              to="/nuevoVendedor"
              active-class="active">
              <button type="button" class="home-title-button btn">Comienza hoy</button>
            </router-link>
        </div>
        <div class="col-12 col-lg-6">
            <div class="home-package-row"></div>
        </div>
    </div>
    <div class="home-ventajas">
        <div class="row">
          <div class="col-12 col-md-4" @mouseover='sellerHeadVentajasAnimation1()' @mouseout='sellerHeadVentajasAnimationOut1()'>
            <div class="home-ventajas-sub-container">
              <p class="home-ventajas-sub-title" id="home-ventajas-sub-title1">DIGITALIZACIÓN</p>
              <h1 class="home-ventajas-title" id="home-ventajas-title1">Sugerencias para aprovechar la digitalización de tu negocio</h1>
              <p id="home-ventajas-text1">Independientemente de si eres un usuario nuevo o existente, estas sugerencias te ayudarán a garantizar el éxito de tus proyectos de Elastic Cloud.</p>
              <svg width="27" height="14" viewBox="0 0 27 14" fill="none" xmlns="http://www.w3.org/2000/svg"  alt="arrow" id="home-ventajas-icon1">
                <path d="M0 7H25" stroke="#307857" stroke-width="2"/>
                <path d="M19 1L25 7L19 13" stroke="#307857" stroke-width="2"/>
              </svg>
            </div>
          </div>
          <div class="col-12 col-md-4" @mouseover='sellerHeadVentajasAnimation2()' @mouseout='sellerHeadVentajasAnimationOut2()'>
            <div class="home-ventajas-sub-container">
              <p class="home-ventajas-sub-title" id="home-ventajas-sub-title2">VENTA ENFOCADA</p>
              <h1 class="home-ventajas-title" id="home-ventajas-title2">Dirígite a tu publico objetivo de forma facil y rápida</h1>
              <p id="home-ventajas-text2">Independientemente de si eres un usuario nuevo o existente, estas sugerencias te ayudarán a garantizar el éxito de tus proyectos de Elastic Cloud.</p>
              <svg width="27" height="14" viewBox="0 0 27 14" fill="none" xmlns="http://www.w3.org/2000/svg" alt="arrow"  id="home-ventajas-icon2">
                <path d="M0 7H25" stroke="#307857" stroke-width="2"/>
                <path d="M19 1L25 7L19 13" stroke="#307857" stroke-width="2"/>
              </svg>
            </div>
          </div>
          <div class="col-12 col-md-4" @mouseover='sellerHeadVentajasAnimation3()' @mouseout='sellerHeadVentajasAnimationOut3()'>
            <div class="home-ventajas-sub-container">
              <p class="home-ventajas-sub-title" id="home-ventajas-sub-title3">VISIBILIDAD</p>
              <h1 class="home-ventajas-title" id="home-ventajas-title3">Mayor visibilidad sin necesidad de campañas de marketing</h1>
              <p id="home-ventajas-text3">Independientemente de si eres un usuario nuevo o existente, estas sugerencias te ayudarán a garantizar el éxito de tus proyectos de Elastic Cloud.</p>
              <svg width="27" height="14" viewBox="0 0 27 14" fill="none" xmlns="http://www.w3.org/2000/svg" alt="arrow"  id="home-ventajas-icon3" >
                <path d="M0 7H25" stroke="#307857" stroke-width="2"/>
                <path d="M19 1L25 7L19 13" stroke="#307857" stroke-width="2"/>
              </svg>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<style scoped>

.home-container{
    background-color: rgb(250, 250, 250);
    background-size: cover;
    padding-bottom: 7%;
}

.home-title-container{
    padding-top: 2%;
    padding-left: 13%;
    padding-right: 13%;   
    text-align: center; 
    --bs-gutter-x: 0rem; 
}

@media only screen and (min-width: 780px) {
  .home-title-container{
    text-align: start;  
  }
}

.home-title{
    padding-top: 10%;
    font-size: 290%;
    font-weight: 900;
    color: #000;
    text-align: start;
    font-family: 'Nunito Sans', sans-serif;
    text-align: center;
}

@media only screen and (min-width: 780px) {
  .home-title{
    padding-top: 5%;
    text-align: start;
    font-weight: 900;
    font-size: 420%;
    color: #000;
  }
} 

.home-subtitle{
    padding-top: 3%;
    font-size: 115%;
    padding-bottom: 3%;
    text-align: center;
}

@media only screen and (min-width: 780px) {
  .home-subtitle{
    padding-top: 2%;
    font-size: 115%;
    text-align: start;
    padding-bottom: 0%;
  }
}

.home-title-button{
    text-align: center;
    margin-top: 3%;
    height: 60px;
    width: 150px;
    border-radius: 5px;
    color: #FFF;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background-color: #307857;
    vertical-align: middle;
}

@media only screen and (min-width: 780px) {
  .home-title-button{
    text-align: center;
  }
}

.home-title-button:hover{
  color: #000;
}

.home-package-row{
    margin-top: 10%;
    background-image: url('../assets/conclusion5.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 200px;
}

@media only screen and (min-width: 780px) {
  .home-package-row{
    height: 330px;
  }
}

.home-ventajas{
  background-color: white;
  margin-top: 10%;
  margin-left: 4%;
  margin-right: 4%;
  margin-bottom: 10%;
  box-shadow: 0px 10px 25px -3px rgba(0,0,0,0.1);
  border-radius: 15px;
}

@media only screen and (min-width: 768px) {
  .home-ventajas{
    margin-top: 5%;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 0%;
  }
}

.home-ventajas-sub-container{
  padding: 7%;
}

.home-ventajas-sub-container{
  text-align: start;
}

.home-ventajas-title{
  font-weight: 900;
  font-size: 150%;
  text-align: start;
}

.home-ventajas-sub-title{
  color: #848484;
  font-weight: 600;
}

</style>

<script>
export default {
  methods: {
    sellerHeadVentajasAnimation1: function () {
      document.getElementById("home-ventajas-sub-title1").style.transition = "transform 0.3s";
      document.getElementById("home-ventajas-sub-title1").style.transform = "translateY(-5px)"
      document.getElementById("home-ventajas-title1").style.transition = "transform 0.3s";
      document.getElementById("home-ventajas-title1").style.transform = "translateY(-5px)";
      document.getElementById("home-ventajas-title1").style.color = "#307857";
      document.getElementById("home-ventajas-text1").style.transition = "transform 0.3s";
      document.getElementById("home-ventajas-text1").style.transform = "translateY(-5px)";
      document.getElementById("home-ventajas-icon1").style.transition = "transform 0.3s";
      document.getElementById("home-ventajas-icon1").style.transform = "translateX(8px)";
      document.getElementsByTagName("body")[0].style.cursor = "pointer";

    },
    sellerHeadVentajasAnimationOut1: function () {
      document.getElementById("home-ventajas-sub-title1").style.transition = "transform 0.3s";
      document.getElementById("home-ventajas-sub-title1").style.transform = "translateY(0px)"
      document.getElementById("home-ventajas-title1").style.transition = "transform 0.3s";
      document.getElementById("home-ventajas-title1").style.transform = "translateY(0px)"
      document.getElementById("home-ventajas-title1").style.color = "#000";
      document.getElementById("home-ventajas-text1").style.transition = "transform 0.3s";
      document.getElementById("home-ventajas-text1").style.transform = "translateY(0px)";
      document.getElementById("home-ventajas-icon1").style.transition = "transform 0.3s";
      document.getElementById("home-ventajas-icon1").style.transform = "translateX(0px)"
      document.getElementsByTagName("body")[0].style.cursor = "auto";
    },
    sellerHeadVentajasAnimation2: function () {
      document.getElementById("home-ventajas-sub-title2").style.transition = "transform 0.3s";
      document.getElementById("home-ventajas-sub-title2").style.transform = "translateY(-5px)"
      document.getElementById("home-ventajas-title2").style.transition = "transform 0.3s";
      document.getElementById("home-ventajas-title2").style.transform = "translateY(-5px)";
      document.getElementById("home-ventajas-title2").style.color = "#307857";
      document.getElementById("home-ventajas-text2").style.transition = "transform 0.3s";
      document.getElementById("home-ventajas-text2").style.transform = "translateY(-5px)";
      document.getElementById("home-ventajas-icon2").style.transition = "transform 0.3s";
      document.getElementById("home-ventajas-icon2").style.transform = "translateX(8px)";
      document.getElementsByTagName("body")[0].style.cursor = "pointer";

    },
    sellerHeadVentajasAnimationOut2: function () {
      document.getElementById("home-ventajas-sub-title2").style.transition = "transform 0.3s";
      document.getElementById("home-ventajas-sub-title2").style.transform = "translateY(0px)"
      document.getElementById("home-ventajas-title2").style.transition = "transform 0.3s";
      document.getElementById("home-ventajas-title2").style.transform = "translateY(0px)"
      document.getElementById("home-ventajas-title2").style.color = "#000";
      document.getElementById("home-ventajas-text2").style.transition = "transform 0.3s";
      document.getElementById("home-ventajas-text2").style.transform = "translateY(0px)";
      document.getElementById("home-ventajas-icon2").style.transition = "transform 0.3s";
      document.getElementById("home-ventajas-icon2").style.transform = "translateX(0px)"
      document.getElementsByTagName("body")[0].style.cursor = "auto";
    },
    sellerHeadVentajasAnimation3: function () {
      document.getElementById("home-ventajas-sub-title3").style.transition = "transform 0.3s";
      document.getElementById("home-ventajas-sub-title3").style.transform = "translateY(-5px)"
      document.getElementById("home-ventajas-title3").style.transition = "transform 0.3s";
      document.getElementById("home-ventajas-title3").style.transform = "translateY(-5px)";
      document.getElementById("home-ventajas-title3").style.color = "#307857";
      document.getElementById("home-ventajas-text3").style.transition = "transform 0.3s";
      document.getElementById("home-ventajas-text3").style.transform = "translateY(-5px)";
      document.getElementById("home-ventajas-icon3").style.transition = "transform 0.3s";
      document.getElementById("home-ventajas-icon3").style.transform = "translateX(8px)";
      document.getElementsByTagName("body")[0].style.cursor = "pointer";

    },
    sellerHeadVentajasAnimationOut3: function () {
      document.getElementById("home-ventajas-sub-title3").style.transition = "transform 0.3s";
      document.getElementById("home-ventajas-sub-title3").style.transform = "translateY(0px)"
      document.getElementById("home-ventajas-title3").style.transition = "transform 0.3s";
      document.getElementById("home-ventajas-title3").style.transform = "translateY(0px)"
      document.getElementById("home-ventajas-title3").style.color = "#000";
      document.getElementById("home-ventajas-text3").style.transition = "transform 0.3s";
      document.getElementById("home-ventajas-text3").style.transform = "translateY(0px)";
      document.getElementById("home-ventajas-icon3").style.transition = "transform 0.3s";
      document.getElementById("home-ventajas-icon3").style.transform = "translateX(0px)"
      document.getElementsByTagName("body")[0].style.cursor = "auto";
    }
  }
}
</script>