<template>
  <div class="home">
    <SellersHeadComponent/>
    <SellersExhibitor/>
    <SellersInfo/>
    <SellersConclusion/>
    <PageFooter/>
  </div>
</template>

<script>
import SellersHeadComponent from '@/components/SellersHeadComponent.vue'
//import SellersHead from '@/components/SellersHeadComponent.vue'
import SellersExhibitor from '@/components/SellersExhibitorComponent.vue'
import SellersInfo from '@/components/SellersInfoComponent.vue'
import SellersConclusion from '@/components/SellersConclusion.vue'
import PageFooter from '@/components/PageFooter.vue'

export default {
  components: {
    //SellersHead,
    SellersHeadComponent,
    SellersExhibitor,
    SellersInfo,
    SellersConclusion,
    PageFooter
}
}
</script>

<style scoped>
  

</style>