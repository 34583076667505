<template>
  <div class="home">
    <HomeHeadComponent/>
    <HomeAppProcess/>
    <HomeAppInfo/>
    <PageFooter2/>
  </div>
</template>

<script>
import HomeHeadComponent from '@/components/HomeHeadComponent.vue'
import HomeAppProcess from '@/components/HomeAppProcessComponent.vue'
import HomeAppInfo from '@/components/HomeAppInfoComponent.vue'
import PageFooter2 from '@/components/PageFooter2.vue'
export default {
  components: {
    HomeHeadComponent,
    HomeAppProcess,
    HomeAppInfo,
    PageFooter2
  }
}
</script>
