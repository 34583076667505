<template>
  <div class="help-head-container">
    <h1 class="help-head-title">¿En qué te podemos ayudar?</h1>
    <p class="help-head-subtitle">Si tienes algun problema con alguno de nuestros productos, desde aquí te podemos ayudar a solucionarlo.</p>
    <div class="help-services-row row">
        <div class="col-0 col-md-3"></div>
        <div class="col-12 col-md-2 p-2">
            <div id="help1" @mouseover='animationHelp1()' @mouseout='animationOutHelp1()' class="help-item-row col-12">
                <div class="process-item-container">
                    <img id="process1-icon" src="../assets/dashboard2.png" height="40" alt="serach"/>
                    <h1 class="help-item-title">Dashboard Avasto</h1>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-2 p-2">
            <div id="help2" @mouseover='animationHelp2()' @mouseout='animationOutHelp2()' class="help-item-row col-12">
                <div class="process-item-container">
                    <img id="process1-icon" src="../assets/mobileApp2.png" height="40" alt="serach"/>
                    <h1 class="help-item-title">Aplicación móvil Avasto</h1>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-2 p-2">
            <div id="help3" @mouseover='animationHelp3()' @mouseout='animationOutHelp3()' class="help-item-row col-12">
                <div class="process-item-container">
                    <img id="process1-icon" src="../assets/question3.png" height="40" alt="serach"/>
                    <h1 class="help-item-title">Preguntas frecuentes</h1>
                </div>
            </div>
        </div>
        <div class="col-0 col-md-3"></div>
    </div>
    <h1 class="help-nosolution-title">¿No encuentras una solución a tu problema?</h1>
    <p class="help-head-subtitle">Si no encuentras información o sigues teniendo dudas, puedes contactar con nuestro soporte técnico. Estaremos encantado de poder ayudarte con tu problema y solucionarlo lo antes posible</p>
    <div class="title-button-container">
        <router-link
            class=""
            to="/"
            active-class="active">
            <button type="button" class="title-button btn">Contactar con soporte</button>
        </router-link>
    </div>
  </div>
</template>

<script>
export default {
    methods: {
        animationHelp1: function () {
            document.getElementById("help1").style.transition = "box-shadow 0.4s";
            document.getElementById("help1").style["boxShadow"] = "rgba(0, 0, 0, 0.10) 0px 8px 16px";
            document.getElementsByTagName("body")[0].style.cursor = "pointer";
        },
        animationOutHelp1: function () {
            document.getElementById("help1").style["boxShadow"] = "none";
            document.getElementsByTagName("body")[0].style.cursor = "auto";
        },
        animationHelp2: function () {
            document.getElementById("help2").style.transition = "box-shadow 0.4s";
            document.getElementById("help2").style["boxShadow"] = "rgba(0, 0, 0, 0.10) 0px 8px 16px";
            document.getElementsByTagName("body")[0].style.cursor = "pointer";
        },
        animationOutHelp2: function () {
            document.getElementById("help2").style["boxShadow"] = "none";
            document.getElementsByTagName("body")[0].style.cursor = "auto";
        },
        animationHelp3: function () {
            document.getElementById("help3").style.transition = "box-shadow 0.4s";
            document.getElementById("help3").style["boxShadow"] = "rgba(0, 0, 0, 0.10) 0px 8px 16px";
            document.getElementsByTagName("body")[0].style.cursor = "pointer";
        },
        animationOutHelp3: function () {
            document.getElementById("help3").style["boxShadow"] = "none";
            document.getElementsByTagName("body")[0].style.cursor = "auto";
        },
    }
}
</script>

<style scoped>

.help-head-container{
    background-color: rgb(252, 252, 252);
}

.help-head-title{
    font-size: 310%;
    font-weight: 900;
    color: #000;
    text-align: center;
    font-family: 'Nunito Sans', sans-serif;
}

@media only screen and (min-width: 1350px) {
  .help-head-title{
    padding-top: 4%;
    text-align: center;
    font-size: 350%;
    color: #000;
  }
} 

.help-head-subtitle{
  padding-top: 3%;
  font-size: 115%;
  padding-left: 4%;
  padding-right: 4%;
  font-family: 'Nunito Sans', sans-serif;
}

@media only screen and (min-width: 768px) {
  .help-head-subtitle{
    padding-top: 1%;
    padding-left: 20%;
    padding-right: 20%;
    font-size: 115%;
  }
} 


.help-services-row{
    padding-top: 2%;
    padding-right: 10%;
    padding-left: 10%;
    --bs-gutter-x: 0rem;
}

.help-item-title{
    font-weight: 800;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 140%;
    padding-top: 3%;
}

.help-item-row{
    padding: 10%;
    background-color: white;
    border-radius: 15px;
    border: 1px solid rgb(213, 213, 213);
    text-align: start;
    height: 100%;
    text-align: start;
}

.help-nosolution-title{
    padding-top: 4%;
    font-size: 150%;
    font-family: "Glacial Indifference", sans-serif;
    font-weight: 600;
}

.title-button-container{
  padding-top: 10%;
  display: inline-block;
}

@media only screen and (min-width: 768px) {
  .title-button-container{
    padding-top: 2%;
    display: inline-block;
  }
}

.title-button{
  height: 60px;
  width: 210px;
  border-radius: 5px;
  color: #FFF;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: #000;
  vertical-align: middle;
}

.title-button:hover{
    color: #000;
}

</style>