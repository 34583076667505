import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SellersView from '../views/SellersView.vue'
import HelpView from '../views/HelpView.vue'
import NewSellerView from '../views/NewSellerView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/vendedores',
    name: 'vendedores',
    component: SellersView
  },
  {
    path: '/nuevoVendedor',
    name: 'Nuevo Vendedor',
    component: NewSellerView
  },
  {
    path: '/soporte',
    name: 'Soporte',
    component: HelpView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
