<template>
  <div class="main">
    <HelpHeadComponent/>
    <HelpDashboardComponent/>
  </div>
</template>

<script>
import HelpHeadComponent from '@/components/HelpHeadComponent.vue'
import HelpDashboardComponent from '@/components/HelpDashboardComponent.vue'
export default {
    components: {
        HelpHeadComponent,
        HelpDashboardComponent
    }
}
</script>

<style>

</style>