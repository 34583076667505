<template>
  <div class="sellers-container">
      <h1 class="sellers-title">Algunas marcas ya confían en nosotros</h1>
      <p class="sellers-subtitle">Desde productores primerizos en el sector que buscan dar a conocer su productos hasta los productores más veteranos en busca de una nueva vertiente de mercado. Todos estamos en avasto.</p>
      <div class="sellers-table">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-3">
            <div class="seller-logo-container seller1-container"></div>
          </div>
          <div class="col-12 col-lg-3">
            <div class="seller-logo-container seller2-container"></div>
          </div>
          <div class="col-12 col-lg-3">
            <div class="seller-logo-container seller3-container"></div>
          </div>
        </div>
      </div>
      <div class="">
        <div class="grid-exhibitor-container">
            <p class="more-text" id="exhibitor-text-animation" @mouseover='exhibitorAnimation()' @mouseout='exhibitorAnimationOut()'>Ver todas las marcas</p>
            <svg width="27" height="14" viewBox="0 0 27 14" fill="none" xmlns="http://www.w3.org/2000/svg" id="exhibitor-arrow-animation" >
                <path d="M0 7H25" stroke="#307857" stroke-width="2"/>
                <path d="M19 1L25 7L19 13" stroke="#307857" stroke-width="2"/>
            </svg>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  methods: {
      exhibitorAnimation: function () {
        document.getElementById("exhibitor-arrow-animation").style.transition = "transform 0.3s";
        document.getElementById("exhibitor-arrow-animation").style.transform = "translateX(8px)";
        document.getElementById("exhibitor-text-animation").style.textDecoration = "underline";
        document.getElementsByTagName("body")[0].style.cursor = "pointer";
      },
      exhibitorAnimationOut: function () {
        document.getElementById("exhibitor-arrow-animation").style.transform = "translateX(0px)";
        document.getElementById("exhibitor-text-animation").style.textDecoration = "none";
        document.getElementsByTagName("body")[0].style.cursor = "auto";
      }
    }
}
</script>

<style scoped>
.sellers-container{
  background-color: rgb(245, 247, 250);
  padding-bottom: 4%;
}

.sellers-title{
  padding-top: 10%;
  padding-right: 4%;
  padding-left: 4%;
  padding-bottom: 5%;
  text-align: center;
  font-weight: 900;
  font-size: 200%;
  color: #000;
}

@media only screen and (min-width: 768px) {
  .sellers-title{
    font-size: 250%;
    padding-top: 5%;
    padding-bottom: 0%;
  }
}

.sellers-subtitle{
  padding-top: 0.5%;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 3%;
  font-size: 110%;
}

@media only screen and (min-width: 768px) {
  .sellers-subtitle{
    padding-top: 0.5%;
    padding-left: 20%;
    padding-right: 20%;
    font-size: 110%;
    padding-bottom: 0%;
  }
}

.sellers-table{
  padding-left: 20%;
  padding-right: 20%;
  padding-top: 2%;
}

.seller-logo-container{
  padding: 5%;
  background-color: white;
  border-radius: 10px;
  height: 130px;
  align-items: center;
  justify-content: center;
}

.seller-logo-container img{
    max-width: 100%;
    height: auto;
    width: auto; /* ie8 */
}

.more-text{
  text-align: center;
  padding-top: 1%;
  font-size: 110%;
  color: #307857;
  font-weight: 700;
  padding-bottom: 3%;
  padding-left: 0%;
  padding-right: 0%;
}

@media only screen and (min-width: 768px) {
  .more-text{
    padding-top: 2%;
    padding-bottom: 0%;
  }
}

.grid-exhibitor-container{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-top: 3%;
}

.grid-exhibitor-container p{
  padding: 0;
  margin: 0 10px 0 0;
}

.seller1-container {
    background-image: url('../assets/marca1-2.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.seller2-container {
    background-image: url('../assets/marca2.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.seller3-container {
    background-image: url('../assets/marca4.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

</style>