<template>
  <div>
    <h1 class="new-seller-title">Comienza a crear un nuevo flujo de ventas</h1>
    <p class="new-seller-subtitle">Rellenando este formulario, nos pondremos en contacto contigo lo antes posible.</p>
    <div class="product-new-seller-row"></div>
  </div>
</template>

<script>
export default {
   
    
}
</script>

<style scoped>

.new-seller-title{
  padding-top: 10%;
  padding-right: 4%;
  padding-left: 4%;
  padding-bottom: 5%;
  text-align: center;
  font-weight: 900;
  font-size: 200%;
  color: #000;
}

@media only screen and (min-width: 768px) {
  .new-seller-title{
    font-size: 250%;
    padding-top: 5%;
    padding-bottom: 0%;
  }
}

.new-seller-subtitle{
  padding-top: 0.5%;
  padding-left: 20%;
  padding-right: 20%;
  font-size: 110%;
  padding-bottom: 1%;
}

.product-new-seller-row{
    background-image: url('../assets/conclusion2.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 200px;
    margin: 6%;
}

@media only screen and (min-width: 1013px) {
    .product-new-seller-row{
        height: 300px;
        margin: 0%;
    }
}

</style>